<template>
  <div class="layout-search">
    <esmp-search
      ref="search"
      placeholder="Начните поиск отсюда"
      :loading="loading"
      :remote-method="debounceSearch"
      :results="results"
      :show-search-results="showSearchResults"
      @on-query-change="onQueryChange"
      @on-select="onSelect"
      @keydown.enter="onEnter"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapState, mapActions } from 'vuex';
import { SEARCH_DELAY, MIN_QUERY_LENGTH } from '@/constants/search';

export default {
  name: 'LayoutSearch',
  data() {
    return {
      loading: false,
      query: '',
      showSearchResults: false,
    };
  },
  computed: {
    ...mapState('search', ['results']),
  },
  methods: {
    ...mapActions('search', ['setResults']),
    searchServices(query) {
      if (query && this.query.length >= MIN_QUERY_LENGTH) {
        this.loading = true;
        this.$API.services.simpleSearch(query).then(({ data }) => {
          this.setResults(data);
        }).finally(() => {
          this.showSearchResults = true;
          this.loading = false;
        });
      } else {
        this.showSearchResults = false;
        this.setResults([]);
      }
    },
    onQueryChange(q) {
      this.query = q;
    },
    onEnter() {
      this.$router.push({ path: '/admin/search', query: { q: this.query } });
    },
    onSelect(v) {
      this.$router.push({ name: 'Forms', params: { serviceId: v.value } });
    },
    clearQuery() {
      this.$refs.search.setQuery(null);
    },
  },
  created() {
    this.debounceSearch = debounce(this.searchServices, SEARCH_DELAY);
  },
  watch: {
    $route: {
      handler(v) {
        this.$nextTick(() => {
          if (v.name === 'AdminSearch') this.showSearchResults = false;
          if (!['Forms', 'AdminSearch'].includes(v.name) && this.query) {
            this.clearQuery();
          }
          if (v.query.q) {
            this.$refs.search.setQuery(v.query.q);
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.layout-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
}
</style>
